import '../styles/future-academy-agenda.css';

import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useContext, useState } from 'react';

import { Cities } from './data/agenda';

const FutureAcademyAgenda = () => {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);

  const [currentCity, setCurrentCity] = useState();

  React.useEffect(() => {
    const getAgenda = () => {
      if (language === 'pl') {
        setCurrentCity(Cities.pl[0]);
      } else {
        setCurrentCity(Cities.en[0]);
      }
    };
    getAgenda();
  }, [language]);

  function changeCity(city) {
    if (language === 'pl') {
      setCurrentCity(Cities.pl[city]);
    } else {
      setCurrentCity(Cities.en[city]);
    }
  }

  const renderAgenda = (currentCity) => {
    return currentCity.agenda.map((city) => (
      <div key={city.hour} className="faa-hour-con">
        <div className="inside-con1">
          <p className="p-body">{city.hour}</p>
        </div>
        <div className="inside-con2">
          <p className="p-body">{city.name}</p>
          <div className="con2-right">
            {city.isIcon ? (
              <>{city.icon}</>
            ) : (
              <div className="text-p">
                <p className="p-body-semibold">{city.text}</p>
              </div>
            )}
            <div className="con2-right-content">
              <svg
                className="con2-right-content-image"
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none">
                <g clip-path="url(#clip0_826_274)">
                  <path
                    d="M8.50001 13.8333C11.0773 13.8333 13.1667 11.744 13.1667 9.16667C13.1667 6.58934 11.0773 4.5 8.50001 4.5C5.92268 4.5 3.83334 6.58934 3.83334 9.16667C3.83334 11.744 5.92268 13.8333 8.50001 13.8333Z"
                    stroke="#52368C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 7.16669V9.16669H9.83333"
                    stroke="#52368C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.16668 3.16669L3.33334 4.50002"
                    stroke="#52368C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.8333 3.16669L13.6667 4.50002"
                    stroke="#52368C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_826_274">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0.5 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p className="p-body-semibold">{city.duration}</p>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="faa-ctn">
        <div className="container">
          <h2>{t`future-academy.faa-title`}</h2>
          {currentCity && (
            <>
              <div className="faa-towns-con">
                <div onClick={() => changeCity(0)}>
                  <h4
                    className={
                      currentCity.title === 'Warszawa' ||
                        currentCity.title === 'Warsaw'
                        ? 'first-h4 current-city'
                        : 'first-h4'
                    }>
                    {t`future-academy.faa-warsaw`}
                  </h4>
                </div>
                <div onClick={() => changeCity(1)}>
                  <h4
                    className={
                      currentCity.title === 'Wrocław' ||
                        currentCity.title === 'Wroclaw'
                        ? 'current-city'
                        : ''
                    }>
                    {t`future-academy.faa-wroclaw`}
                  </h4>
                </div>
                <div onClick={() => changeCity(2)}>
                  <h4
                    className={
                      currentCity.title === 'Kraków' ||
                        currentCity.title === 'Cracow'
                        ? 'current-city'
                        : ''
                    }>
                    {t`future-academy.faa-cracow`}
                  </h4>
                </div>
              </div>
              <h3>{currentCity.title}</h3>
              {renderAgenda(currentCity)}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FutureAcademyAgenda;
