import '../styles/edition-is-over.css';

import React, { useState } from 'react';

import { FaGalleryImages } from '../../../mocks/fa-gallery';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const EditionIsOver = () => {
  const { t } = useTranslation();

  const [showImage, setShowImage] = useState(false);


  const renderImages = () => {
    return FaGalleryImages.map((image, index) => (
      <div
        key={index}
        onClick={() => openImageInNewCard(image.src.default)}
        className={`shade-border ${index < 6 || showImage ? '' : 'hidden'}`}>
        <div className="shade-image"></div>
        <img
          className="eio-image"
          src={image.src.default}
          alt="Image"
          placeholder="none"
          loading="lazy"
        />
      </div>
    ));
  };

  const openImageInNewCard = (src) => {
    window.open(window.location.origin + src, '_blank');
  };

  return (
    <>
      <div className="eio-ctn">
        <div className="container">
          <h2>{t`future-academy.eio-title`}</h2>
          <p className="p-body ">{t`future-academy.eio-title2`}</p>
          <div className="eio-grid">{FaGalleryImages && renderImages()}</div>
          <div className="btn-eio-container">
            <button
              onClick={() => setShowImage(!showImage)}
              className="fa-btn btn-eio">
              {!showImage
                ? `${t`future-academy.eio-button`}`
                : `${t`future-academy.eio-button-less`}`}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditionIsOver;
